@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 0px;

  @media (--viewportLarge) {
    padding-bottom: 260px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  width: 100%;
  margin: 0px 0 14px 0;

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidthPages) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidthPages);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 150px;
  @media (--viewportLarge) {
    height: auto;
    overflow-y: initial;
    padding-bottom: 0;
  }
  & .productGallery {
    position: relative;
    margin: 0 0 36px 0;

    @media (--viewportMedium) {
      margin: 24px 0 48px 0;
    }
    @media (--viewportLarge) {
      margin: 0 0 40px 0;
      padding-bottom: 4px;
    }
  }
}
.listingDrawer {
  height: 100dvh !important;
}
.closeDrawer {
  padding: 20px 24px;
  cursor: pointer;
  transition: var(--transitionStyle);
  align-self: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  @media (--viewportLarge) {
    position: relative;
    align-self: flex-start;
  }
  &:hover {
    transition: var(--transitionStyle);
    & > svg {
      & path {
        stroke: none;
        fill: var(--colorBlack);
        transition: var(--transitionStyle);
        @media (--viewportLarge) {
        }
      }
    }
  }

  & > svg {
    & path {
      transition: var(--transitionStyle);
      stroke: none;
      fill: var(--marketplaceColor);
      @media (--viewportLarge) {
        fill: var(--colorWhite);
      }
    }
  }
}
.contentWrapperForProductLayout {
  width: 100%;
  background-color: var(--colorWhite);
  padding: 24px;
  transform: translateZ(0px);
  box-sizing: border-box;
  height: 100%;

  @media (--viewportLarge) {
    width: calc(100% - 36px - 48px);
    height: auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 130%;
  margin: 0;
  color: var(--colorBlack);
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 32px;
  margin-bottom: 32px;
  & .sectionHeading {
    font-size: 20px;
    font-weight: var(--fontWeightBold);
    line-height: 130%;
    margin: 0 0 8px 0;
    color: var(--colorBlack);
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 130%;
  margin: 0 0 8px 0;
  color: var(--colorBlack);
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.text {
  color: var(--colorGrey500);
  font-weight: 400;
  line-height: 130%;
  font-size: 15px;
  margin: 0;
  white-space: normal;
  word-break: break-word;
}

.ingress {
  color: var(--colorGrey500);
  font-weight: 400;
  line-height: 130%;
  font-size: 15px;
  margin: 0;
  white-space: normal;
  word-break: break-word;
}

.sectionDetails {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: solid 1px var(--colorGrey100);
}
.sectionMultiEnum {
  margin-bottom: 20px;
}

.sectionMap {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: solid 1px var(--colorGrey100);
  & img {
    width: 100%;
  }
}

.sectionAuthor {
}
.details {
  background-color: var(--colorGrey50);
  margin-top: 14px;
  margin-bottom: 0;
  border: solid 1px var(--colorGrey100);
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  line-height: 23px;
  list-style: none;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
  color: var(--colorGrey700);
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(50vh - 193px);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  /* Static map: dimensions are 640px */
  max-height: 640px;
  min-height: 200px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  z-index: 0;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}
.listingPageHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
    align-items: center;
  }
  & .listingPageHeaderLeft {
    margin-bottom: 10px;
    @media (--viewportSmall) {
      margin-bottom: 0;
    }
    & .orderPanelTitle {
      font-size: 24px;
      line-height: 130%;
      margin: 0;
      color: var(--colorBlack);

      @media (--viewportMedium) {
        font-size: 30px;
      }
    }
    & > p {
      display: flex;
      align-items: center;
      margin: 0 0 8px 0;
      flex-wrap: wrap;
      @media (--viewportSmall) {
        flex-wrap: nowrap;
      }
      &:last-child {
        margin-bottom: 0;
      }
      & > span {
        color: var(--colorGrey500);
        font-weight: 400;
        line-height: 110%;
        font-size: 16px;
        margin: 0;
        &.separator {
          margin: 0 5px;
          font-size: 20px;
          line-height: 0;
          margin-top: -7px;
        }
        &.reviews,
        &.location {
          &:hover {
            cursor: pointer;
          }
        }
        & > svg {
          width: 14px;
          height: 14px;
          margin-top: -4px;
          & path {
            fill: var(--colorBlack);
          }
        }
      }
    }
  }
  & .listingPageHeaderRight {
    display: flex;
    align-items: center;
    & .shareBtn,
    & .saveBtn {
      border: none;
      text-decoration: underline;
      color: var(--colorBlack);
      font-weight: 400;
      line-height: 110%;
      padding: 0;
      background-color: hsl(0deg 0% 95.43%);
      width: 40px;
      height: 40px;
      border-radius: 100px;
      & > svg {
      }
    }
    & .shareBtn {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
        color: var(--marketplaceColor);
        & > svg {
          & path {
            stroke: var(--marketplaceColor);
          }
        }
      }
      & > svg {
        width: 18px;
        height: 18px;
        & path {
          stroke: var(--colorBlack);
        }
      }
    }
    & .saveBtn {
      & > svg {
        width: 18px;
        height: 18px;
        & path {
          stroke: none;
          fill: var(--colorBlack);
        }
      }
      &:hover {
        cursor: pointer;
        color: var(--marketplaceColor);
        & > svg {
          & path {
            stroke: none;
            fill: var(--marketplaceColor);
          }
        }
      }
    }
  }
}
.mobileGallarySlider {
  margin-bottom: 24px;
  & :global(.slick-dots) {
    bottom: 20px;
    & > li {
      & > button {
        &::before {
          font-size: 10px;
          color: var(--colorWhite);
          opacity: 1;
        }
      }
    }
  }
  & :global(.slick-active > button::before) {
    color: var(--marketplaceColor) !important;
  }
  & .imgBox {
    position: relative;
    & > a {
      padding-bottom: 50%;
      display: inline-block;
      width: 100%;
      position: relative;

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}
.gallerySec {
  margin-bottom: 48px;
  & .photosGridContainer {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    align-items: start;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: 580px) {
      grid-template-columns: 1fr;
    }

    & .imgBox {
      border: 1px solid #ffffff;
      position: relative;
      transition: ease-in-out 0.3s;
      &:hover {
        transition: ease-in-out 0.3s;

        & > a {
          transition: ease-in-out 0.3s;
          &::before {
            transform: scale(1);
            transition: ease-in-out 0.3s;
          }
        }
      }
      & > a {
        display: inline-block;
        transition: ease-in-out 0.3s;
        &::before {
          width: 100%;
          height: 100%;
          content: '';
          background-color: rgba(0, 0, 0, 0.2);
          transform: scale(0);
          top: 0;
          left: 0;
          position: absolute;
          transition: ease-in-out 0.3s;
        }
      }
      & img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    & .mainPhoto {
      grid-row: 1;
      grid-column: 1;
    }
    & .smallimages {
      display: grid;
      position: relative;
      & .sub {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 5px;

        &:nth-child(0) {
          grid-column: 1;
          grid-row: 1;
        }

        &:nth-child(1) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(2) {
          grid-column: 1;
          grid-row: 2;
        }

        &:nth-child(3) {
          grid-column: 2;
          grid-row: 2;
        }
      }
      & .showAll {
        position: absolute;
        bottom: 20px;
        right: 20px;
        background-color: var(--colorWhite);
        border: 1px solid var(--colorGrey100);
        border-radius: 6px;
        padding: 8px 16px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        transition: var(--transitionStyle);
        &:hover {
          transition: var(--transitionStyle);
          background-color: var(--marketplaceColor);
          color: var(--colorWhite);
          border-color: var(--marketplaceColor);
          & > svg {
            transition: var(--transitionStyle);
            & rect {
              stroke: var(--colorWhite);
              transition: var(--transitionStyle);
            }
          }
        }
        & > svg {
          transition: var(--transitionStyle);
          margin-right: 8px;
          width: 16px;
          height: 16px;
          & rect {
            stroke: var(--colorBlack);
            transition: var(--transitionStyle);
          }
        }
      }
    }
  }
}

.mainContentWrapper {
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
  & .mainContentLeft {
    width: 100%;
    @media (--viewportLarge) {
      width: calc(100% - 400px);
      padding-right: 40px;
    }
    & .listingPageHeader {
      border-bottom: solid 1px var(--colorGrey100);
      align-items: flex-start;
      padding-bottom: 32px;
      margin-bottom: 32px;
      & .listingPageHeaderLeft {
        & .orderPanelTitle {
          margin-bottom: 8px;
        }
      }
    }
    & .userProfile {
      width: 56px;
      height: 56px;
      border-radius: 100px;
      overflow: hidden;
      position: relative;
      & > img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    & .experienceSec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 32px;
      align-items: flex-start;
      @media (--viewportSmall) {
        grid-template-columns: repeat(3, 1fr);
      }
      & .experienceBlock {
        display: flex;
        flex-direction: column;
        &:last-child {
          margin-bottom: 0;
        }
        & .experienceIcon {
          & > svg {
            width: 20px;
            height: 20px;
            & path {
              fill: var(--colorBlack);
              opacity: 1;
            }
          }
        }
        & .experienceInfo {
          margin-top: 15px;
          & > h2 {
            font-size: 18px;
            color: var(--colorBlack);
            margin: 0 0 8px 0;
            line-height: 100%;
          }
          & > p {
            font-size: 16px;
            color: var(--colorGrey500);
            margin: 0;
            line-height: 100%;
          }
        }
      }
    }
  }
  & .mainContentRight {
    width: 100%;
    align-self: flex-start;
    display: flex;
    @media (--viewportLarge) {
      border: solid 1px rgba(17, 17, 17, 0.04);
      position: sticky;
      max-width: 400px;
      top: 30px;
      padding: 20px;
      border-radius: 10px;
      background-color: rgb(245, 245, 244);
    }

    & .productOrderPanel {
      @media (--viewportLarge) {
        display: block;
      }
    }
  }
}
.mobileThingsToKnowSlider {
  & :global(.slick-dots) {
    & > li {
      & > button {
        &::before {
          font-size: 10px;
          color: var(--colorGrey200);
          opacity: 1;
        }
      }
    }
  }
  & :global(.slick-active > button::before) {
    color: var(--marketplaceColor) !important;
  }
  & .rulesBlock {
    width: 100%;

    & > h2 {
      font-size: 18px;
      line-height: 130%;
      color: var(--colorBlack);
      font-weight: 600;
      margin: 0 0 8px 0;
    }
    & > p {
      font-size: 15px;
      line-height: 130%;
      color: var(--colorGrey500);
      font-weight: 400;
      margin: 0 0 8px 0;
    }
    & .showMore {
      color: var(--colorBlack);
      text-decoration: underline;
      font-size: 16px;
      font-weight: 600;
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColor);
        & > svg {
          & path {
            fill: var(--marketplaceColor);
          }
        }
      }
      & > svg {
        width: 12px;
        height: 12px;
        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}
.thingsToKnowSec {
  & > h1 {
    margin-bottom: 24px;
  }
  & .thingsToKnow {
    display: flex;
    & .rulesBlock {
      width: calc(100% / 3);
      min-width: 300px;

      & > h2 {
        font-size: 18px;
        line-height: 130%;
        color: var(--colorBlack);
        font-weight: 600;
        margin: 0 0 8px 0;
      }
      & > p {
        font-size: 15px;
        line-height: 130%;
        color: var(--colorGrey500);
        font-weight: 400;
        margin: 0 0 8px 0;
      }
      & .showMore {
        color: var(--colorBlack);
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
        transition: var(--transitionStyle);
        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColor);
          & > svg {
            & path {
              fill: var(--marketplaceColor);
            }
          }
        }
        & > svg {
          width: 12px;
          height: 12px;
          & path {
            fill: var(--colorBlack);
          }
        }
      }
    }
  }
}
.seeMoreText {
  border: none;
  text-decoration: underline;
  box-shadow: none;
  color: var(--marketplaceColor);
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 0;
  cursor: pointer;
  transition: var(--transitionStyle);
  &:hover {
    transition: var(--transitionStyle);
    color: var(--marketplaceColorDark);
    & > svg {
      & path {
        fill: var(--marketplaceColorDark);
      }
    }
  }
  & > svg {
    width: 12px;
    height: 12px;
    & path {
      fill: var(--colorBlack);
    }
  }
}
.aboutBedroom {
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 32px;
  margin-bottom: 32px;
}
.sliderSec {
  width: 100%;
  margin-top: 14px;
  & .bedDetails {
    border: solid 1px var(--colorGrey100);
    border-radius: 10px;
    padding: 24px;
    & > svg {
      margin-bottom: 5px;
      /* & path {
        fill: var(--colorBlack);
      } */
    }
    & > h2 {
      font-size: 16px;
      color: var(--colorBlack);
      line-height: 120%;
      margin: 5px 0;
    }
    & > p {
      color: var(--colorGrey500);
      margin: 0;
      line-height: 120%;
      font-size: 15px;
    }
  }
  & :global(.slick-slider) {
    max-width: 100%;
    width: 100%;
  }
  & :global(.slick-list) {
    position: relative;
    z-index: 0;
    margin: 0px -5px;
  }
  & :global(.slick-slide) {
    padding: 0px 5px;
  }
  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: inline-flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey100);
    transition: var(--transitionStyle);
    &:hover {
      background-color: var(--colorGrey50);
      border: solid 1px var(--colorGrey500);
      transition: var(--transitionStyle);
    }
  }
  & :global(.slick-prev::before),
  & :global(.slick-next::before) {
    width: 20px;
    height: 20px;
    content: ' ';
    display: inline-flex;
    background-size: 20px;
  }
  & :global(.slick-prev::before) {
    background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB0cmFuc2Zvcm09InJvdGF0ZSgxODApIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjI5Mjg5IDQuMjkyODlDOC42ODM0MiAzLjkwMjM3IDkuMzE2NTggMy45MDIzNyA5LjcwNzExIDQuMjkyODlMMTYuNzA3MSAxMS4yOTI5QzE3LjA5NzYgMTEuNjgzNCAxNy4wOTc2IDEyLjMxNjYgMTYuNzA3MSAxMi43MDcxTDkuNzA3MTEgMTkuNzA3MUM5LjMxNjU4IDIwLjA5NzYgOC42ODM0MiAyMC4wOTc2IDguMjkyODkgMTkuNzA3MUM3LjkwMjM3IDE5LjMxNjYgNy45MDIzNyAxOC42ODM0IDguMjkyODkgMTguMjkyOUwxNC41ODU4IDEyTDguMjkyODkgNS43MDcxMUM3LjkwMjM3IDUuMzE2NTggNy45MDIzNyA0LjY4MzQyIDguMjkyODkgNC4yOTI4OVoiIGZpbGw9IiMwMDAwMDAiLz4gPC9nPgoNPC9zdmc+);
  }
  & :global(.slick-next::before) {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOC4yOTI4OSA0LjI5Mjg5QzguNjgzNDIgMy45MDIzNyA5LjMxNjU4IDMuOTAyMzcgOS43MDcxMSA0LjI5Mjg5TDE2LjcwNzEgMTEuMjkyOUMxNy4wOTc2IDExLjY4MzQgMTcuMDk3NiAxMi4zMTY2IDE2LjcwNzEgMTIuNzA3MUw5LjcwNzExIDE5LjcwNzFDOS4zMTY1OCAyMC4wOTc2IDguNjgzNDIgMjAuMDk3NiA4LjI5Mjg5IDE5LjcwNzFDNy45MDIzNyAxOS4zMTY2IDcuOTAyMzcgMTguNjgzNCA4LjI5Mjg5IDE4LjI5MjlMMTQuNTg1OCAxMkw4LjI5Mjg5IDUuNzA3MTFDNy45MDIzNyA1LjMxNjU4IDcuOTAyMzcgNC42ODM0MiA4LjI5Mjg5IDQuMjkyODlaIiBmaWxsPSIjMDAwMDAwIi8+DQo8L3N2Zz4=);
  }
  & :global(.slick-prev) {
    left: -14px;
  }

  & :global(.slick-next) {
    right: -14px;
  }
}

.moreInfoHost {
  margin: 12px 0;
  & .review {
    margin: 12px 0;
    color: var(--colorBlack);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    & > span {
      &:not(:first-child) {
        margin-left: 10px;
      }
      & > svg {
        margin-top: -4px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
      /* &:not(:last-child) {
        margin-right: 20px;
        & > svg {
          & path {
            fill: var(--colorBlack);
          }
        }
      }
      &:last-child {
        & > svg {
          & path {
            stroke: var(--colorBlack);
          }
          & rect {
            stroke: var(--colorBlack);
          }
        }
      } */
    }
  }
  & .description {
    margin: 12px 0;
  }
}
.authorDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--viewportSmall) {
    flex-direction: row;
    align-items: center;
  }
  & .contactBlock {
    margin-top: 10px;
    @media (--viewportSmall) {
      margin-top: 0;
    }
    & .contactbtn {
      border: solid 1px var(--colorGrey400);
      background-color: var(--colorWhite);
      color: var(--colorBlack);
      padding: 10px 20px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
      cursor: pointer;
      transition: var(--transitionStyle);
      &:hover {
        background-color: var(--marketplaceColor);
        color: var(--colorWhite);
        border-color: var(--marketplaceColor);
        transition: var(--transitionStyle);
      }
    }
  }
}
.fixedNavbar {
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  border-bottom: solid 1px var(--colorGrey100);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  & .navbarContent {
    max-width: calc(var(--contentMaxWidthPages) + 72px);
    width: 100%;
    margin: 0 auto;
    padding: 0 14px;
    @media (--viewportLarge) {
      padding: 0 20px;
    }
    & > span {
      color: var(--colorBlack);
      font-size: 16px;
      line-height: 100%;
      padding: 30px 8px;
      display: inline-block;
      cursor: pointer;
      @media (--viewportMedium) {
        padding: 30px 15px;
        font-size: 18px;
      }
      @media (--viewportLarge) {
        font-size: 18px;
        padding: 30px 20px;
      }
      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }
}
.host {
  margin-bottom: 14px;
  & > span {
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey100);
    padding: 8px 15px;
    border-radius: 10px;
    color: var(--colorGrey700);
    line-height: 120%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      & path {
        fill: var(--colorBlack);
      }
    }
  }
}
.roomDetailSec {
  display: flex;
  align-items: center;
  margin-top: 10px;
  float: left;
  width: 100%;
  flex-wrap: wrap;
  & > span {
    margin-right: 30px;
    font-size: 16px;
    color: var(--colorGrey500);
    line-height: 100%;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.beds {
      & > img {
        position: relative;
        bottom: 3px;
        margin-right: 4px;
      }
      & > svg {
        & path {
          stroke: var(--colorBlack);
          fill: none;
          stroke-width: 1.5px;
        }
      }
    }
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      & path {
        fill: var(--colorBlack);
      }
    }
  }
}
.sectionAmenities {
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 32px;
  margin-bottom: 32px;
}
.workSpaceInfo {
  display: flex;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: solid 1px var(--colorGrey100);
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  & .workSpaceBlock {
    width: 100%;
    @media (--viewportMedium) {
      width: calc(100% / 3);
    }
    &:not(:last-child) {
      margin-bottom: 32px;
      @media (--viewportMedium) {
        margin-bottom: 0;
      }
    }
    & > span {
      width: 56px;
      height: 56px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(77, 127, 255, 0.129);
      border-radius: 100px;
      margin-bottom: 15px;
      & > svg {
        width: 20px;
        height: 20px;
        & path {
          fill: var(--marketplaceColor);
          opacity: 1;
        }
      }
    }

    & > h2 {
      font-size: 16px;
      font-weight: var(--fontWeightSemiBold);
      color: var(--colorBlack);
      margin: 0 0 8px 0;
      line-height: 100%;
    }
    & > p {
      font-size: 15px;
      color: var(--colorGrey500);
      margin: 0;
      line-height: 100%;
    }
  }
}
.amenitiesProvided {
  & .amenities {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    margin-top: 14px;
    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }
    & > span {
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey800);
      line-height: 100%;
      display: inline-flex;
      align-items: center;
      & > svg {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        & path {
          fill: var(--colorGrey800);
        }
      }
    }
  }
}
.viewAllAmenities {
  color: var(--marketplaceColor);
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  margin-top: 32px;
  transition: var(--transitionStyle);
  &:hover {
    color: var(--colorBlack);
    transition: var(--transitionStyle);
  }
}
.sectionReviews {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: solid 1px var(--colorGrey100);
  & .sectionHeadingWithExtraMargin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .totalReviews {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: var(--fontWeightRegular);
      & > svg {
        margin-right: 5px;
        width: 12px;
        height: 12px;
        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}
.givenRatingSec {
  border: solid 1px var(--colorGrey100);
  padding: 24px;
  border-radius: 10px;
  & .ratingBlock {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    & > span {
      width: 50%;
      font-size: 15px;
      color: var(--colorBlack);
      margin: 0;
      line-height: 100%;
    }
    & .ratingProgress {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > progress {
        width: 100%;
        margin-right: 10px;
        height: 10px;
        border: none;

        &::-webkit-progress-bar {
          background-color: var(--colorGrey100);
          border-radius: 100px;
        }
        &::-webkit-progress-value {
          background-color: var(--marketplaceColor);
          border-radius: 100px;
        }
        &::-moz-progress-bar {
          background-color: var(--colorGrey100);
          border-radius: 100px;
        }
      }
      & > span {
        font-size: 15px;
        color: var(--colorBlack);
        font-weight: var(--fontWeightRegular);
      }
    }
  }
}
.topReviews {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin-top: 24px;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
  & .reviewBlock {
    border: solid 1px var(--colorGrey100);
    padding: 24px;
    border-radius: 10px;
    & .reviewerSec {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      & .userImg {
        width: 56px;
        height: 56px;
        border-radius: 100px;
        overflow: hidden;
        & > img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .userInfo {
        width: calc(100% - 56px);
        padding-left: 10px;
        & > h2 {
          font-size: 20px;
          font-weight: var(--fontWeightBold);
          line-height: 120%;
          margin: 0 0 4px 0;
          color: var(--colorBlack);
        }
        & > p {
          color: var(--colorGrey500);
          font-size: 15px;
          line-height: 130%;
          margin: 0;
        }
      }
    }
  }
}
.locationDetails {
  margin-top: 10px;
  & > h2 {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    line-height: 120%;
    margin: 0 0 8px 0;
    color: var(--colorBlack);
  }
  & > p {
    color: var(--colorGrey500);
    font-size: 15px;
    line-height: 130%;
    margin: 0;
  }
}

.checkout {
  margin-top: 4px !important;
}

.amenitiesProvided {
  margin-bottom: 30px;
}

.label {
  position: relative;
  top: 8px;
  left: 4px;
}

.icon {
  & > img {
    width: 30px;
  }
}
